import React, { useState, useEffect, useCallback } from "react";
import { Modal, Box, TextField, ListItemText, List, IconButton, ListItem, CircularProgress, Paper, Button } from "@mui/material";
import SearchIcon from "@mui/icons-material/Search";
import Articulos from "../api/articulos";
import AddCircleIcon from "@mui/icons-material/AddCircle";
import RemoveCircleIcon from "@mui/icons-material/RemoveCircle";
import { useTheme } from "@mui/material/styles";
import { useNavigate } from "react-router-dom";
import debounce from "lodash.debounce";
import MenuSection from "../components/MenuSection";
import apiErrorHandler from "../helpers/apiErrorHandler"; // Importa el helper

// Importar hooks personalizados
import { useProductStore } from "../hooks/useProductStore";
import { useArticleStore } from "../hooks/useArticleStore";

const ArticulosSearchPage = () => {
  const [searchTerm, setSearchTerm] = useState("");
  const [searchResults, setSearchResults] = useState([]);
  const [modalWidth, setModalWidth] = useState("500px");
  const [isLoading, setIsLoading] = useState(false);
  const [articulosArray, setArticulosArray] = useState([]);
  const [activeArticulos, setActiveArticulos] = useState({});
  const [contadorGlobal, setContadorGlobal] = useState({});
  const [showButtons, setShowButtons] = useState(false);
  const [showAllResults] = useState(false);

  const theme = useTheme();
  const navigate = useNavigate();

  // Usar hooks personalizados en lugar de useSelector y useDispatch
  const { productos, idProductoArray, addProducto, setIdProductoArray, clearProductos } = useProductStore();
  const { articulosClickeados, addArticulo, removeArticulo, resetArticulos } = useArticleStore();

  function filtrarArticulos(articulos) {
    const perteneceFULL = JSON.parse(localStorage.getItem("perteneceFULL"));
    return articulos.filter(articulo => (perteneceFULL ? articulo.codigoYPF !== null : articulo.codigoYPF === null));
  }

  useEffect(() => {
    const fetchData = async () => {
      setIsLoading(true);
      try {
        let articulosFiltrados = [];
        const response = await Articulos.getArticulosHabilitados("");
        let articulos = response.data.articulos;
        articulos = filtrarArticulos(articulos);
        articulosFiltrados = Object.values(articulos).sort((a, b) => a.descripcion.localeCompare(b.descripcion));
        setSearchResults(articulosFiltrados);
        setArticulosArray(articulosFiltrados);
      } catch (error) {
        console.error("Error al obtener los datos:", error);
        apiErrorHandler(error, { stopFlow: true });
      } finally {
        setIsLoading(false);
      }
    };

    fetchData();

    const handleResize = () => {
      if (window.innerWidth < 700) {
        setModalWidth("360px");
      } else {
        setModalWidth("900px");
      }
    };

    handleResize();
    window.addEventListener("resize", handleResize);

    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, []);

  useEffect(() => {
    const eventListener = async event => {
      const barcode = event.detail.barcode;
      setSearchTerm(barcode);

      const articuloEncontrado = articulosArray.find(articulo => articulo.codigoBarra.toString() === barcode);

      if (articuloEncontrado) {
        handleIncrementScanner(articuloEncontrado);
      }
    };

    window.addEventListener("scanComplete", eventListener);

    return () => {
      window.removeEventListener("scanComplete", eventListener);
    };
  }, [articulosArray]);

  const handleAbrirScanner = () => {
    window.MyInterface.scanProduct();
  };

  const handleSearch = async () => {
    const searchTermLowerCase = searchTerm.toLowerCase().trim();
    setIsLoading(true);
    try {
      let results = [];
      const response = await Articulos.getArticulosHabilitados(searchTermLowerCase);
      let articulos = response.data.articulos;
      articulos = filtrarArticulos(articulos);
      setArticulosArray(articulos);
      results = Object.values(articulos).sort((a, b) => a.descripcion.localeCompare(b.descripcion));
      setSearchResults(results.slice(0, showAllResults ? results.length : 50));
      setShowButtons(true);
    } catch (error) {
      console.error("Error al obtener los datos:", error);
      apiErrorHandler(error, { stopFlow: true });
    } finally {
      setIsLoading(false);
    }
  };

  const debouncedHandleSearch = useCallback(debounce(handleSearch, 1000), [searchTerm]);

  useEffect(() => {
    if (searchTerm) {
      debouncedHandleSearch();
    }
    return () => {
      debouncedHandleSearch.cancel();
    };
  }, [searchTerm, debouncedHandleSearch]);

  const handleAgregar = () => {
    const totalArticulosConImporte = articulosClickeados.map(articulo => ({
      ...articulo,
      importe: articulo.cantidadArticulo * articulo.precioPublico,
    }));

    let idProductoArrayActual = idProductoArray;

    totalArticulosConImporte.forEach(articulo => {
      if (!articulo.idProductoArray) {
        articulo.idProductoArray = idProductoArrayActual;
        idProductoArrayActual += 1;
      }
      addProducto(articulo);
    });

    setIdProductoArray(idProductoArrayActual);

    const esPlaya = localStorage.getItem("solicitarAforadores");
    if (esPlaya === "true") {
      navigate("/dashboard");
    } else {
      navigate("/dashboardmini");
    }
    handleClose();
  };

  const handleClose = () => {
    resetArticulos();
    setSearchTerm("");
    setSearchResults([]);
  };

  const handleCancelar = () => {
    setSearchTerm("");
    setSearchResults([]);
    window.history.back();
  };

  const handleIncrement = articulo => {
    addArticulo(articulo);
    setActiveArticulos(prev => ({
      ...prev,
      [articulo.idArticulo]: true,
    }));
    setContadorGlobal(prev => ({
      ...prev,
      [articulo.idArticulo]: (prev[articulo.idArticulo] || 0) + 1,
    }));
  };

  const handleIncrementScanner = articulo => {
    addArticulo(articulo);
    setActiveArticulos(prev => ({
      ...prev,
      [articulo.idArticulo]: true,
    }));
    setContadorGlobal(prev => ({
      ...prev,
      [articulo.idArticulo]: (prev[articulo.idArticulo] || 0) + 1,
    }));
  };

  const handleDecrement = articulo => {
    if (contadorGlobal[articulo.idArticulo] > 0) {
      setContadorGlobal(prev => ({
        ...prev,
        [articulo.idArticulo]: prev[articulo.idArticulo] - 1,
      }));
    }

    if (contadorGlobal[articulo.idArticulo] <= 1) {
      setActiveArticulos(prev => ({
        ...prev,
        [articulo.idArticulo]: false,
      }));
    }
    removeArticulo(articulo);
  };

  return (
    <>
      <MenuSection />
      <Box
        sx={{
          position: "absolute",
          width: modalWidth,
          top: window.innerWidth < 700 ? "48%" : "56.5%",
          left: "50%",
          transform: "translate(-50%, -50%)",
          backgroundColor: "white",
          p: "8px 25px 0px 25px",
          height: window.innerWidth < 700 ? "80%" : "86%",
        }}
      >
        <Box
          sx={{
            display: "flex",
            flexDirection: window.innerWidth > 700 ? "row" : "column",
            alignItems: window.innerWidth < 700 ? "left" : "center",
            marginBottom: 2,
            whiteSpace: "nowrap",
          }}
        >
          <Button
            variant="contained"
            sx={{
              boxShadow: 0,
              fontWeight: "bold",
              fontSize: 13,
              mb: window.innerWidth > 700 ? 0 : 1,
              marginRight: window.innerWidth > 700 ? 2 : 0,
              backgroundColor: theme.bandera.colorSecundario,
              ":hover": {
                backgroundColor: theme.bandera.colorSecundario,
              },
            }}
            onClick={handleAbrirScanner}
          >
            LEER CODIGO DE BARRAS
          </Button>

          <TextField
            label="Nombre o código del artículo"
            value={searchTerm}
            onChange={e => {
              setSearchTerm(e.target.value);
              debouncedHandleSearch();
            }}
            variant="outlined"
            fullWidth
            sx={{
              flexGrow: 1,
              mb: window.innerWidth > 700 ? 0 : 2,
              width: window.innerWidth > 700 ? "70%" : "100%",
            }}
            InputProps={{
              endAdornment: (
                <IconButton>
                  <SearchIcon sx={{ height: "2rem", width: "2rem" }} />
                </IconButton>
              ),
            }}
            InputLabelProps={{
              style: { fontSize: "0.85rem", color: "gray" },
            }}
          />
        </Box>

        {isLoading ? (
          <div
            style={{
              display: "flex",
              justifyContent: "center",
              paddingTop: "8rem",
              height: window.innerWidth < 700 ? 367 : 291,
            }}
          >
            <CircularProgress sx={{ color: theme.bandera.colorSecundario }} />
          </div>
        ) : (
          <>
            <Paper
              sx={{
                height: window.innerWidth < 700 ? 350 : 275,
                overflow: "auto",
                boxShadow: 0,
                marginBottom: 2,
              }}
            >
              {searchResults.map(
                (
                  articulo, // Cambia 'articulosArray' por 'searchResults' aquí
                ) => (
                  <List key={articulo.idArticulo}>
                    <ListItem
                      key={1}
                      sx={{
                        borderBottom: "1px solid #ccc",
                        display: "flex",
                      }}
                    >
                      <ListItemText
                        primary={`${articulo.descripcion}`}
                        secondary={`$${articulo.precioPublico.toFixed(2)}`}
                        sx={{
                          display: "flex",
                          justifyContent: "space-between",
                          "& .MuiListItemText-primary": {
                            marginRight: 1.5, // Ajusta el espacio entre primary y secondary
                          },
                        }}
                      />
                      <div
                        style={{
                          display: "grid",
                          textAlign: "center",
                          marginLeft: 20,
                        }}
                      >
                        {activeArticulos[articulo.idArticulo] && (
                          <TextField
                            value={contadorGlobal[articulo.idArticulo] || 0}
                            size="small"
                            disabled
                            style={{
                              width: 70,
                              border: "none", // Quita el borde
                              padding: 0,
                              textAlign: "center", // Centra horizontalmente el texto
                            }}
                          />
                        )}
                        <div
                          style={{
                            display: "grid",
                            gridTemplateColumns: "1fr 1fr",
                            gap: "10px",
                          }}
                        >
                          <AddCircleIcon sx={{ color: theme.bandera.colorSecundario, fontSize: "2.5em" }} onClick={() => handleIncrement(articulo)} />
                          <RemoveCircleIcon sx={{ color: theme.bandera.colorSecundario, fontSize: "2.5rem" }} onClick={() => handleDecrement(articulo)} />
                        </div>
                      </div>
                    </ListItem>
                  </List>
                ),
              )}
            </Paper>
          </>
        )}
        <div style={{ display: "flex", justifyContent: "space-between" }}>
          <>
            <Box
              sx={{
                display: "flex",
                justifyContent: window.innerWidth < 700 ? "center" : "flex-end",
                width: "100%",
                boxSizing: "border-box",
              }}
            >
              <Button
                variant="contained"
                sx={{
                  boxShadow: 0,
                  fontWeight: "bold",
                  fontSize: 17,
                  marginRight: window.innerWidth < 700 ? 5 : 0,
                  backgroundColor: theme.bandera.colorSecundario,
                  ":hover": {
                    backgroundColor: theme.bandera.colorSecundario,
                  },
                }}
                onClick={handleAgregar}
              >
                AGREGAR
              </Button>
              <Button
                variant="contained"
                sx={{
                  marginLeft: "1rem",
                  boxShadow: 0,
                  fontWeight: "bold",
                  fontSize: 17,
                  backgroundColor: theme.bandera.colorSecundario,
                  ":hover": {
                    backgroundColor: theme.bandera.colorSecundario,
                  },
                }}
                onClick={handleCancelar}
              >
                CANCELAR
              </Button>
            </Box>
          </>
        </div>
      </Box>
    </>
  );
};

export default ArticulosSearchPage;
